import styles from './Faq.module.scss';
import Accordion from '@/components/molecules/Accordion';
import { type AccordionProps } from '@/components/molecules/Accordion/Accordion';
import { memo, useEffect, useMemo, useRef } from 'react';

interface FAQItem {
  editor: string;
  title: string;
}

interface FaqProps {
  faqs: FAQItem[];
  style: 'guide' | 'page';
}

type SchemaAttributes = {
  itemScope: true;
  itemType: `https://schema.org/${string}`;
  itemProp?: string;
};

/**
 * Schema URL constants to ensure consistency and avoid typos
 */
const SCHEMA_URLS = {
  FAQ_PAGE: 'https://schema.org/FAQPage',
  QUESTION: 'https://schema.org/Question',
  ANSWER: 'https://schema.org/Answer',
} as const;

/**
 * Schema attribute names that need to be managed
 */
const SCHEMA_ATTRIBUTES = ['itemscope', 'itemprop', 'itemtype'] as const;

// Static flag to ensure only one FAQ component has schema
let firstFAQMounted = false;

/**
 * FAQ Component that implements schema.org FAQ Page markup
 * Ensures only the first instance on a page gets schema attributes to avoid duplicates
 */
export const Faq = ({ faqs, style }: FaqProps): JSX.Element | null => {
  const componentRef = useRef<HTMLDivElement>(null);
  const isMounted = useRef(false);

  useEffect(() => {
    // Prevent effect from running multiple times
    if (isMounted.current) return;
    isMounted.current = true;

    const currentComponent = componentRef.current;
    if (!currentComponent) return;

    // Only first FAQ component should have schema
    const shouldHaveSchema = !firstFAQMounted;
    if (!firstFAQMounted) {
      firstFAQMounted = true;
    } else {
      // Clean up schema attributes from non-first components
      SCHEMA_ATTRIBUTES.forEach(attr => {
        currentComponent.removeAttribute(attr);
        currentComponent.querySelectorAll(`[${attr}]`).forEach(el => {
          el.removeAttribute(attr);
        });
      });
    }

    // Cleanup on unmount
    return () => {
      if (shouldHaveSchema) {
        firstFAQMounted = false;
      }
    };
  }, []);

  const accordionRows: AccordionProps['rows'] = useMemo(
    () =>
      faqs
        .filter((faq): faq is FAQItem => Boolean(faq.title))
        .map(({ editor, title }) => ({
          content: [{ editor }],
          title,
        })),
    [faqs],
  );

  if (!accordionRows.length) return null;

  /**
   * Helper to generate schema attributes with correct types
   */
  const getSchemaProps = (type: string, prop?: string): SchemaAttributes => ({
    itemScope: true,
    itemType: `https://schema.org/${type}`,
    ...(prop ? { itemProp: prop } : {}),
  });

  const schemaProps = firstFAQMounted && componentRef.current === document.querySelector('[itemtype*="FAQPage"]')
    ? getSchemaProps('FAQPage')
    : {};

  return (
    <div
      ref={componentRef}
      className={styles[style]}
      {...schemaProps}
    >
       <Accordion
        rows={accordionRows}
        rowProps={() => firstFAQMounted ? getSchemaProps(SCHEMA_URLS.QUESTION.split('/').pop()!, 'mainEntity') : {}}
        contentProps={() => firstFAQMounted ? getSchemaProps(SCHEMA_URLS.ANSWER.split('/').pop()!, 'acceptedAnswer') : {}}
        headingProps={() => firstFAQMounted ? { itemProp: 'name' } : {}}
        textProps={() => firstFAQMounted ? { itemProp: 'text' } : {}}
      />
    </div>
  );
};

export default memo(Faq);